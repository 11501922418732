<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-0">
          <v-btn @click="cancel(false)"> cancel </v-btn>
          <v-btn
            data-cy="trip-event-save-btn"
            v-if="canEditSetupTables"
            class="float-right"
            color="green"
            dark
            @click="onSubmit"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-form :readonly="value.id > 0 && !canEditSetupTables" data-cy="trip-event-form">
        <v-container>
          <v-row dense>
            <v-col cols="12" md="4">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="Trip Type"
                :value="value.tripTypeId"
                v-model="$v.form.tripTypeId.$model"
                :items="tripTypes"
                item-text="name"
                item-value="id"
                outlined
                class="mb-0"
                :error-messages="handleErrors($v.form.tripTypeId)"
                @blur="$v.form.tripTypeId.$touch()"
                data-cy="trip-event-type"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :value="value.name"
                v-model.trim="$v.form.name.$model"
                label="Event"
                required
                :error-messages="handleErrors($v.form.name)"
                @blur="$v.form.name.$touch()"
                data-cy="trip-event-name"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="4">
              <v-checkbox
                :value="value.defaultEvent"
                v-model="$v.form.defaultEvent.$model"
                label="Default Event"
                required
                @change="$v.form.defaultEvent.$touch()"
              ></v-checkbox>
            </v-col>
          </v-row>

          <template v-if="showRateSection">
            <v-row class="my-0 py-0">
              <v-col cols="12" md="4">
                <v-text-field
                  :value="value.mileageRate"
                  v-model.trim="$v.form.mileageRate.$model"
                  label="Mileage Rate Per Mile"
                  required
                  type="number"
                  prepend-inner-icon="mdi-currency-usd"
                  @blur="$v.form.mileageRate.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col cols="12" md="4">
                <v-checkbox
                  :value="value.overrideDriver"
                  v-model="$v.form.overrideDriver.$model"
                  label="Override the Driver Rate"
                  required
                  @change="$v.form.overrideDriver.$touch()"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4" v-if="$v.form.overrideDriver.$model">
                <v-text-field
                  :value="value.driverRate"
                  v-model.trim="$v.form.driverRate.$model"
                  label="Driver Rate Per Hour"
                  required
                  type="number"
                  prepend-inner-icon="mdi-currency-usd"
                  @blur="$v.form.driverRate.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';

const newTripEvent = {
  name: '',
  tripTypeId: null,
  mileageRate: 0,
  overrideDriver: false,
  driverRate: 0,
  defaultEvent: false,
};

export default {
  mixins: [validationMixin],
  components: {},
  data: () => ({
    handleErrors,
    id: null,
    form: newTripEvent,
    saved: false,
  }),
  validations: {
    form: {
      name: { required },
      tripTypeId: { required },
      mileageRate: {},
      overrideDriver: {},
      driverRate: {},
      defaultEvent: {},
    },
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
    ...mapGetters('tripType', ['tripTypesById']),
    showRateSection() {
      if (!this.$v.form.$model.tripTypeId) return false;
      return this.tripTypesById[this.$v.form.$model.tripTypeId].allowEventRates;
    },
  },
  methods: {
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newTripEvent;
        this.$emit('closeForm');
      }
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.$emit('submit', { id: this.id, ...this.$v.form.$model });
      this.saved = true;
    },
    compareValues() {
      if (!this.id && (this.$v.form.$model.name || this.$v.form.$model.tripTypeId)) return true;
      if (!this.id) return false;
      if (
        this.$v.form.$model.name != this.value.name ||
        this.$v.form.$model.tripTypeId != this.value.tripTypeId ||
        this.$v.form.$model.mileageRate != this.value.mileageRate ||
        this.$v.form.$model.overrideDriver != this.value.overrideDriver ||
        this.$v.form.$model.driverRate != this.value.driverRate ||
        this.$v.form.$model.defaultEvent != this.value.defaultEvent
      )
        return true;
      return false;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => newTripEvent,
    },
    tripTypes: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else {
          this.form = { ...newTripEvent, ...value };
        }
      },
    },
    form: {
      deep: true,
      handler: function (value) {
        this.$emit('formChanges', value);
      },
    },
  },
};
</script>
