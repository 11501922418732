var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            "data-cy": "trip-event-save-btn",
                            color: "green",
                            dark: "",
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-form",
            {
              attrs: {
                readonly: _vm.value.id > 0 && !_vm.canEditSetupTables,
                "data-cy": "trip-event-form",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              "menu-props": { bottom: true, offsetY: true },
                              label: "Trip Type",
                              value: _vm.value.tripTypeId,
                              items: _vm.tripTypes,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.tripTypeId
                              ),
                              "data-cy": "trip-event-type",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.tripTypeId.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.tripTypeId.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.tripTypeId, "$model", $$v)
                              },
                              expression: "$v.form.tripTypeId.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.name,
                              label: "Event",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.name
                              ),
                              "data-cy": "trip-event-name",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.name.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.name.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.defaultEvent,
                              label: "Default Event",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.defaultEvent.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.defaultEvent.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.defaultEvent,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.form.defaultEvent.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showRateSection
                    ? [
                        _c(
                          "v-row",
                          { staticClass: "my-0 py-0" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "4" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    value: _vm.value.mileageRate,
                                    label: "Mileage Rate Per Mile",
                                    required: "",
                                    type: "number",
                                    "prepend-inner-icon": "mdi-currency-usd",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.$v.form.mileageRate.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.$v.form.mileageRate.$model,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.$v.form.mileageRate,
                                        "$model",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "$v.form.mileageRate.$model",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "my-0 py-0" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "4" } },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    value: _vm.value.overrideDriver,
                                    label: "Override the Driver Rate",
                                    required: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$v.form.overrideDriver.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.$v.form.overrideDriver.$model,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.$v.form.overrideDriver,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "$v.form.overrideDriver.$model",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.$v.form.overrideDriver.$model
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        value: _vm.value.driverRate,
                                        label: "Driver Rate Per Hour",
                                        required: "",
                                        type: "number",
                                        "prepend-inner-icon":
                                          "mdi-currency-usd",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.$v.form.driverRate.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.$v.form.driverRate.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.form.driverRate,
                                            "$model",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "$v.form.driverRate.$model",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }