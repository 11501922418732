var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("setup-table", {
    attrs: {
      title: `Trip Event`,
      items: _vm.items,
      headers: _vm.headers,
      loading: _vm.loading,
      clearItem: !_vm.showForm || !_vm.editedItem.id,
    },
    on: {
      createItem: function ($event) {
        return _vm.createItem()
      },
      editItem: _vm.editItem,
      deleteItems: _vm.deleteItems,
    },
    scopedSlots: _vm._u(
      [
        _vm.showForm
          ? {
              key: "itemForm",
              fn: function () {
                return [
                  _c("trip-event-form", {
                    ref: "tripEventForm",
                    attrs: {
                      value: _vm.editedItem,
                      tripTypes: _vm.allTripTypes,
                    },
                    on: {
                      submit: _vm.saveItem,
                      closeForm: _vm.closeForm,
                      formChanges: _vm.formChanges,
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: `item.tripType`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  _vm._s(
                    _vm.allTripTypes.find((e) => e.id == item.tripTypeId).name
                  )
                ),
              ]),
            ]
          },
        },
        {
          key: `item.mileageRate`,
          fn: function ({ item }) {
            return [
              !_vm.hasAllowTripEventRateEnabled(item.tripTypeId)
                ? _c(
                    "p",
                    { staticClass: "mb-0" },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  )
                : _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(
                        item.mileageRate
                          ? _vm.toMoney(item.mileageRate, 4)
                          : "-"
                      )
                    ),
                  ]),
            ]
          },
        },
        {
          key: `item.driverRate`,
          fn: function ({ item }) {
            return [
              !_vm.hasAllowTripEventRateEnabled(item.tripTypeId)
                ? _c(
                    "p",
                    { staticClass: "mb-0" },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  )
                : _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(
                        item.overrideDriver
                          ? _vm.toMoney(item.driverRate)
                          : "N/A"
                      )
                    ),
                  ]),
            ]
          },
        },
        {
          key: `item.overrideDriver`,
          fn: function ({ item }) {
            return [
              !_vm.hasAllowTripEventRateEnabled(item.tripTypeId)
                ? _c(
                    "p",
                    { staticClass: "mb-0" },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  )
                : _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(
                      _vm._s(item.overrideDriver ? "mdi-check" : "mdi-close")
                    ),
                  ]),
            ]
          },
        },
        {
          key: `item.defaultEvent`,
          fn: function ({ item }) {
            return [
              item.defaultEvent
                ? _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-check")])
                : _vm._e(),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }