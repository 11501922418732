import { filter, isEqual } from 'lodash';
/**
 * This mixin prevents users from accidentally navigating away from a page with unsaved changes.
 *
 * - `baseFormValue`: Stores the initial value of the form when the component is first loaded or last saved.
 * - `latestFormValue`: Tracks the current value of the form as the user interacts with it.
 * - `willPromptBeforeLeave`: If true, the user is prompted before leaving the page. turn this to false if the prompt is unnecessary for navigation.
 *
 * The `beforeRouteLeave` hook is triggered when the user tries to navigate away from the current route.
 *
 * If `baseFormValue` and `latestFormValue` are different (indicating unsaved changes), the user is prompted
 * with a confirmation dialog. If the user confirms, navigation proceeds. If the user cancels, navigation is prevented.
 *
 * This mixin is useful for forms or inputs where data loss prevention is important.
 */
export default {
  data() {
    return {
      baseFormValue: null,
      latestFormValue: null,
      willPromptBeforeLeave: true,
      excludedFields: [
        'lastUpdated',
        'stops',
        'auditHistory',
        'history',
        'approval',
        'requiredApprovalLevels',
        'assignments',
      ],
      mutatedFields: [
        {
          field: 'criteria',
          filter: (value) => !value.deleted,
          mutation: (value) => ({
            id: value.id,
            label: value.label,
            deleted: value.deleted ?? false,
          }),
          sort: (a, b) => {
            return a.id - b.id;
          },
        },
        {
          field: 'contacts',
          mutation: function (value) {
            return {
              id: value.id ?? 0,
              phone: (
                value.phone ??
                (value.phones !== undefined ? (value.phones[0] !== undefined ? value.phones[0].phone : '') : '')
              ).replace(/-/g, ''),
              email:
                value.email ??
                (value.emails !== undefined ? (value.emails[0] !== undefined ? value.emails[0].email : '') : ''),
              name: value.name ?? '',
              relationship: value.relationship ?? '',
            };
          },
          sort: (a, b) => {
            return a.id - b.id;
          },
        },
        {
          field: 'tripTypes',
          sort: (a, b) => {
            return a - b;
          },
        },
        {
          field: 'vehicleOrder',
          sort: (a, b) => {
            return a - b;
          },
        },
        {
          field: 'vehicleTypes',
          sort: (a, b) => {
            return a - b;
          },
        },
        {
          field: 'displayOnTripTypes',
          sort: (a, b) => {
            return a - b;
          },
        },
      ],
    };
  },

  async beforeRouteLeave(to, from, next) {
    const canLeave = await this.performPromptBeforeLeave();
    next(canLeave);
  },

  methods: {
    cleanFormValues() {
      if (this.baseFormValue === null || this.latestFormValue === null) return;

      this.excludedFields.forEach((field) => {
        if (this.latestFormValue[field] !== undefined) delete this.latestFormValue[field];
        if (this.baseFormValue[field] !== undefined) delete this.baseFormValue[field];
      });

      const keys = Object.keys(this.latestFormValue);

      for (const key of keys) {
        if (key === 'phone') {
          this.latestFormValue[key] =
            typeof this.latestFormValue[key] === 'string'
              ? this.latestFormValue[key].replace(/[^a-zA-Z0-9]/g, '')
              : this.latestFormValue[key];

          this.baseFormValue[key] =
            typeof this.baseFormValue[key] === 'string'
              ? this.baseFormValue[key].replace(/[^a-zA-Z0-9]/g, '')
              : this.baseFormValue[key];
        } else {
          if (!this.nullOrEmpty(this.latestFormValue[key])) {
            this.latestFormValue[key] = isNaN(this.latestFormValue[key])
              ? this.latestFormValue[key]
              : Number(this.latestFormValue[key]);
          }
          if (!this.nullOrEmpty(this.baseFormValue[key])) {
            this.baseFormValue[key] = isNaN(this.baseFormValue[key])
              ? this.baseFormValue[key]
              : Number(this.baseFormValue[key]);
          }
        }

        if (this.nullOrEmpty(this.latestFormValue[key]) && this.baseFormValue[key] === undefined) {
          this.baseFormValue[key] = this.latestFormValue[key];
        } else if (this.nullOrEmpty(this.latestFormValue[key]) && this.nullOrEmpty(this.baseFormValue[key])) {
          this.baseFormValue[key] = this.latestFormValue[key];
        }
      }

      this.mutatedFields.forEach((field) => {
        if (this.baseFormValue[field.field] !== undefined) {
          let bFieldVal = this.baseFormValue[field.field];
          if (field.filter !== undefined) bFieldVal = filter(bFieldVal, field.filter);
          if (field.mutation !== undefined) bFieldVal = bFieldVal.map(field.mutation);
          if (field.sort !== undefined && Array.isArray(bFieldVal)) bFieldVal.sort(field.sort);
          this.baseFormValue[field.field] = bFieldVal;
        }
        if (this.latestFormValue[field.field] !== undefined) {
          let lFieldVal = this.latestFormValue[field.field];
          if (field.filter !== undefined) lFieldVal = filter(lFieldVal, field.filter);
          if (field.mutation !== undefined) lFieldVal = lFieldVal.map(field.mutation);
          if (field.sort !== undefined && Array.isArray(lFieldVal)) lFieldVal.sort(field.sort);
          this.latestFormValue[field.field] = lFieldVal;
        }
      });
    },

    nullOrEmpty(field) {
      return (
        field === undefined ||
        field === null ||
        field === '' ||
        (Object.keys(field).length === 0 && typeof field === 'object')
      );
    },

    /**
     * call this method to prompt the user on Tab change, or any non-route change of screen, i.e: Tabs, etc.
     *
     * */
    async performPromptBeforeLeave() {
      this.cleanFormValues();

      if (!isEqual(this.baseFormValue, this.latestFormValue) && this.willPromptBeforeLeave) {
        const yes = await this.$myalert({
          message: `You have unsaved changes`,
          subMessage: 'Do you really want to leave?',
          type: 'warning',
          buttonLabel: 'Yes',
          cancelButtonLabel: 'Cancel',
        });
        if (yes) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
};
